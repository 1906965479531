// openai.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OpenAIResponse } from 'src/app/_interfaces/openAIResponse';
import { Observable, from, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { SpeechSynthesisService } from './speech-synthesis.service';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root'
})


export class OpenAIService {
  private baseURL: string = "https://api.openai.com/v1/chat/completions";
  private apiKey = "sk-proj-QFv2AjTW13nmYHdPf1HXT3BlbkFJe48UHXWxPDiKhCa4lZpO";
  private messages: { role: string, content: string }[];

  constructor(
    private http: HttpClient,
    private sss: SpeechSynthesisService,
    private orderService: OrderService
  
    ) {
      
    this.messages = [
      {
        role: 'system',
        content: `Du bist Anna. Wenn dich jemand fragt, sag einfach, dass du gerne hilfst, aber dein Lernmodul ausgeschaltet wurde.`
      }
      
    ];
  }

  getResponse(prompt: string): Observable<string> {
    return from(this.main(prompt)).pipe(
      catchError(error => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  private processOrder(order: any): void {
    // Hier kannst du die JSON-Daten verarbeiten
    console.log('Auftrag wurde angelegt:', order);
    alert('Auftrag wurde angelegt');
  }

  public async main(prompt: string): Promise<string> {
    try {
      // Füge die Benutzernachricht zur Nachrichtenliste hinzu
      this.messages.push({ role: 'user', content: prompt });
  
      const response = await fetch(this.baseURL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.apiKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: 'gpt-4',
          messages: this.messages,
          max_tokens: 150,
          temperature: 0.7,
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      let result = '';
      if (data.choices && data.choices.length > 0) {
        result = data.choices[0].message.content;
  
        try {
          const event = JSON.parse(result);
          switch(event.event.type) {
            case 'create_order':
              this.processOrder(event);
              return ''; // Gib keinen Text zurück, um den Chat nicht zu füllen
            case 'amount_orders':
              // Hier die Anzahl der Aufträge zurückgeben
              const amountOrders = 3; // Beispielwert, du solltest hier die echte Anzahl der Aufträge ermitteln
              const text = `Es gibt derzeit ${amountOrders} Patienten im System die nicht abgerechnet sind.`
              this.sss.speak(text);
              return text;
            case 'call':

              const telUrl = `tel:${event.event.number}`;
              // Öffne die tel: URL direkt
              window.location.href = telUrl;
              const text2 = 'ich rufe an!';
              return text2;
            default:
              // Falls ein unbekannter Event-Typ auftritt
              console.error('Unbekannter Event-Typ:', event.event.type);
          }
        } catch (e) {
          // JSON-Parsing fehlgeschlagen, normaler Textinhalt
        }
  
        // Füge die Antwort des Modells zur Nachrichtenliste hinzu
        this.messages.push({ role: 'assistant', content: result });
  
        // Optionale Aktion: Sprich die Antwort aus
        this.sss.speak(result);
      }
  
      return result;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
  

  

  getPromt(prompt: string) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Bearer Ihr-API-Schlüssel' // Ersetzen Sie 'Ihr-API-Schlüssel' mit Ihrem tatsächlichen API-Schlüssel
    });

    const requestBody = {
      prompt: prompt,
      max_tokens: 150
    };

    return this.http.post(this.baseURL, requestBody, { headers: headers });
  }

  createChatCompletion(prompt: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer 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'   // Ersetze mit deinem API-Schlüssel
    });

    const body = {
      "model": "gpt-3.5-turbo",
      "max_tokens": 150,
      "messages": [
          {
              "role": "user",
              "content": "Say this is a test!"
          }
      ]
  }

    return this.http.post(this.baseURL, body, { headers: headers });
  }
}
